import React, { useEffect, useState } from 'react';
import './Community.scss';
import Footer from '../../components/Footer';
import { useLocation, useNavigate } from 'react-router-dom';
import ListTemplate from './ListTemplate';
import MenuTemplate from './MenuTemplate';

export default function BoardNotice() {

  let navigate = useNavigate();
  const [currentMenu, setCurrentMenu] = useState(1);


  return (
    <div className='community'>

      <div className="inner">

        {/* 왼쪽 메뉴바 */}
        <MenuTemplate currentMenu={currentMenu} setCurrentMenu={setCurrentMenu} navigate={navigate} />
  
        <ListTemplate title='공지사항' sort='notice' num={1}/>
       
      </div>

      <Footer />
    </div>
  )
}



