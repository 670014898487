import React, { useEffect, useState } from 'react';
import './Community.scss';
import Footer from '../../components/Footer';
import { useLocation, useNavigate } from 'react-router-dom';
import ListTemplate from './ListTemplate';
import MenuTemplate from './MenuTemplate';

export default function BoardConcours() {

  let navigate = useNavigate();

  const [currentMenu, setCurrentMenu] = useState(2);
  

  return (
    <div className='community'>

      <div className="inner">

        {/* 왼쪽 메뉴바 */}
        <MenuTemplate currentMenu={currentMenu} setCurrentMenu={setCurrentMenu} navigate={navigate} />

        <ListTemplate title='콩쿨정보' sort='concours' num={2}/>

      </div>

      <Footer />
    </div>
  )
}



